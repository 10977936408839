.styleForm .el-input {
  width: 136px;
}
.el-table__header, .el-table__body, .el-table__footer {
  width: 100%;
  table-layout: fixed !important;
}
.xginput {
  border: 1px solid #b3aeae;
  width: 100px;
  height: 28px;
  line-height: 28px;
  color: #606266;
  outline: none;
  padding: 0 10px;
}