
.w-110 {
  width: 66px;
.el-input-number__decrease, .el-input-number__increase{
    display: none!important;
}
.el-input-number .el-input__inner{
    text-align: left!important;
}
}
