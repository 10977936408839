.top_btns .left_box[data-v-8df99d96] {
  float: left;
  width: 70%;
  margin-bottom: 10px;
}
.top_btns .right_box[data-v-8df99d96] {
  float: right;
  width: 30%;
  text-align: right;
}
.styleForm .el-select[data-v-8df99d96] {
  margin: 0px;
}
.bottom_btns[data-v-8df99d96] {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 15px;
}
.bottom_btns .left_box[data-v-8df99d96] {
  float: left;
  width: 70%;
}
.bottom_btns .right_box[data-v-8df99d96] {
  float: right;
  width: 30%;
  text-align: right;
}
.bottom_btns .right_box span[data-v-8df99d96] {
  color: #F15A24;
}
.strTdStyle[data-v-8df99d96] {
  width: 1%;
  border: 1px solid #b3aeae;
  height: 28px;
}
span[data-v-8df99d96] {
  font-size: 12px;
}
.bianma[data-v-8df99d96] {
  cursor: pointer;
}
.bianma.bianma-p[data-v-8df99d96] {
  font-weight: 600;
}
.bianma.bianma-c[data-v-8df99d96] {
  padding-left: 10px;
}
.subject-name.subjectName-one[data-v-8df99d96] {
  cursor: pointer;
  font-weight: 600;
  text-decoration: underline;
  text-underline-offset: 3px;
}
.subject-name.subjectName-two[data-v-8df99d96] {
  cursor: pointer;
  text-decoration: underline;
  text-underline-offset: 3px;
  padding-left: 10px;
}
.subject-name.subjectName-three[data-v-8df99d96] {
  cursor: pointer;
  font-weight: 600;
  font-size: 13px;
  color: var(--themeColor, #17a2b8);
}
.period-begin-in.periodBeginIn-one[data-v-8df99d96] {
  font-weight: 600;
}
.period-begin-in.periodBeginIn-three[data-v-8df99d96] {
  color: var(--themeColor, #17a2b8);
  font-size: 13px;
  font-weight: 600;
}
.periodBeginInWb[data-v-8df99d96] {
  color: var(--themeColor, #17a2b8);
  font-size: 13px;
  font-weight: 600;
}
.pad-r-12[data-v-8df99d96] {
  padding-right: 12px;
}