@charset "UTF-8";
.searchBtn[data-v-8fa305f2] {
  text-align: center;
}
.searchBtn button[data-v-8fa305f2] {
  margin-right: 5px;
}
.top_btns[data-v-8fa305f2] {
  margin-bottom: 20px;
}
.top_btns .left_box[data-v-8fa305f2] {
  float: left;
  width: 70%;
}
.top_btns .right_box[data-v-8fa305f2] {
  float: right;
  width: 30%;
  text-align: right;
}
.elTree[data-v-8fa305f2] {
  width: 100%;
  height: 500px;
  overflow: scroll;
  border: 1px solid #ede5e5;
}
[data-v-8fa305f2] .el-tree-node {
  min-width: 100%;
  display: table;
}
[data-v-8fa305f2]::-webkit-scrollbar {
  width: 8px;
}

/* 滚动槽 */
[data-v-8fa305f2]::-webkit-scrollbar-track {
  box-shadow: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

/* 滚动条滑块 */
[data-v-8fa305f2]::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(17, 16, 16, 0.13);
  box-shadow: rgba(0, 0, 0, 0.5);
}
[data-v-8fa305f2]::-webkit-scrollbar-thumb:window-inactive {
  background: #efefef;
}
.leftButtonIcon[data-v-8fa305f2] {
  position: relative;
  top: 255px;
  font-size: 20px;
  color: var(--themeColor, #17a2b8);
  cursor: pointer;
}