@charset "UTF-8";
.searchGjz[data-v-744ce068] {
  border: 1px solid #ebeef5;
}
.top_btns .left_box[data-v-744ce068] {
  float: left;
  width: 70%;
}
.top_btns .right_box[data-v-744ce068] {
  float: right;
  width: 30%;
  text-align: right;
}
.center_main[data-v-744ce068] {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  line-height: 32px;
  margin-top: 20px;
}
.elTree[data-v-744ce068] {
  width: 100%;
  height: 500px;
  overflow: scroll;
  border: 1px solid #ede5e5;
}
[data-v-744ce068]::-webkit-scrollbar {
  width: 8px;
}

/* 滚动槽 */
[data-v-744ce068]::-webkit-scrollbar-track {
  box-shadow: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

/* 滚动条滑块 */
[data-v-744ce068]::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(17, 16, 16, 0.13);
  box-shadow: rgba(0, 0, 0, 0.5);
}
[data-v-744ce068]::-webkit-scrollbar-thumb:window-inactive {
  background: #efefef;
}