.searchGjz[data-v-1bd4bb48] {
  border: 1px solid #ebeef5;
}
.searchBtn[data-v-1bd4bb48] {
  text-align: center;
}
.searchBtn button[data-v-1bd4bb48] {
  margin-right: 5px;
  padding: 10px 0 10px 0;
}
.top_btns[data-v-1bd4bb48] {
  margin-bottom: 10px;
}
.top_btns .left_box[data-v-1bd4bb48] {
  float: left;
  width: 70%;
}
.top_btns .right_box[data-v-1bd4bb48] {
  float: right;
  width: 30%;
  text-align: right;
}
.bianma[data-v-1bd4bb48] {
  cursor: pointer;
  color: #17a2b8;
}