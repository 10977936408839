.app-container h5[data-v-4e913503] {
  font-size: 18px;
  color: #333;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 30px;
}
.top_btns .left_box[data-v-4e913503] {
  float: left;
  width: 76%;
}
.top_btns .right_box[data-v-4e913503] {
  float: right;
  width: 20%;
  text-align: right;
}
.top_btns .time_box[data-v-4e913503] {
  float: left;
}
.top_btns .bottom_btns[data-v-4e913503] {
  float: left;
}
.tableDl[data-v-4e913503] {
  margin-top: 15px;
}
.period_style[data-v-4e913503] {
  margin-left: 0px;
  width: 136px;
}