.top_btns .left_box[data-v-fe9d8c18] {
  float: left;
  width: 70%;
  margin-bottom: 10px;
}
.top_btns .right_box[data-v-fe9d8c18] {
  float: right;
  width: 30%;
  text-align: right;
}
.styleForm .el-select[data-v-fe9d8c18] {
  margin: 0px;
}
span[data-v-fe9d8c18] {
  font-size: 12px;
}