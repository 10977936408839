.biaotou[data-v-23be18ab] {
  line-height: 25px;
  font-size: 14px;
  color: #333;
  width: 100%;
  padding: 5px 0 10px 0;
}
.biaotou span[data-v-23be18ab] {
  display: inline-block;
  width: 33%;
}
.biaotou span[data-v-23be18ab]:nth-child(1) {
  text-align: left;
}
.biaotou span[data-v-23be18ab]:nth-child(2) {
  text-align: center;
}
.biaotou span[data-v-23be18ab]:nth-child(3) {
  text-align: right;
}
.top_btns[data-v-23be18ab] {
  display: flex;
  justify-content: space-between;
}
.top_btns .left_box[data-v-23be18ab] {
  float: left;
  width: 70%;
  height: 50px !important;
}
.top_btns .right_box[data-v-23be18ab] {
  float: right;
  width: 30%;
  text-align: right;
}
.top_p[data-v-23be18ab] {
  font-size: 15px;
  color: #333;
  margin-top: 2px;
  margin-left: -15%;
}